import './NavBar.css'
import {Typography} from "@material-ui/core";

import { Link} from 'react-router-dom';
import React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import logo from "../favicon.png";
import menuItems from '../menuItems.js';
import { setActivePage, selectActivePage, selectIsMobile } from '../features/appSlice.js';


function NavBar(){
    const activePage = useSelector(selectActivePage);
    const dispatch = useDispatch();
    const isMobile = useSelector(selectIsMobile);

    const activeTextStyle =   "d-inline changeHoverColor fw-bold fs-6 text-white";
    const inactiveTextStyle = "d-inline changeHoverColor fw-bold fs-6 navtabColor";
    const activeTabStyle =   "text-decoration-none ms-5 text-center pb-1 border-bottom border-2 border-white";
    const inactiveTabStyle = "text-decoration-none ms-5 text-center pb-1";

    return(
        <>
            { (!isMobile) &&
                <div className="nav nav-tabs bg-black text-white d-flex align-items-center"  id="navBar">
                    {/* logo */}
                    <a onClick={() => dispatch(setActivePage(0))} className='ms-4 me-5 d-flex flex-column justify-content-center align-items-center pt-3 pb-3 me-2' href="/" style={{"text-decoration": "none"}}>
                            <img className="bg-light rounded-circle border-0"  src={logo} alt="cuttlefish logo"/>
                            <h5 className='xborder m-0 text-white' >Cuttlefish®</h5>
                    </a>
                    {
                        //home
                        menuItems.filter(menuItem => menuItem.link === undefined).filter(element => element.target === "/").map((item) => 
                            <Link
                            onClick={() => dispatch(setActivePage(item.key))}  
                            className={activePage===item.key? activeTabStyle : inactiveTabStyle} 
                            xstyle={{minWidth: 120}}  key={item.key} to={item.target}><Typography className={activePage===item.key? activeTextStyle : inactiveTextStyle}>{item.label.toUpperCase()}</Typography></Link>
                        )

                    }
                    {/* dropdown */}
                    <li className="nav-item dropdown">
                        <button className="xm-3 nav-link text-white dropdown-toggle border-0 p-0 pb-1 ms-5" data-bs-toggle="dropdown" aria-expanded="false">
                            <Typography className={menuItems.filter(item => item.inMenu && item.inDropdown && item.key === activePage).length? activeTextStyle : inactiveTextStyle}>
                                CUTTLEFISH
                            </Typography>
                        </button>
                        <ul className="dropdown-menu">
                            {
                                menuItems
                                .filter(item => item.inMenu && item.inDropdown)
                                .map((item, index) => 
                                {
                                    if(item.link !== undefined){
                                        return <a 
                                                target="_blank" 
                                                rel="noreferrer"
                                                className={activePage===item.key? "dropdown-item dropdownStyle border-bottom border-2 border-primary text-primary":"dropdown-item dropdownStyle"}
                                                href={item.link}>
                                                    <Typography>{item.label.toUpperCase()}</Typography>
                                                </a>
                                    }
                                    else{
                                       return <Link 
                                        onClick={() => dispatch(setActivePage(item.key))} 
                                        className={activePage===item.key? "dropdown-item dropdownStyle border-bottom border-2 border-primary text-primary":"dropdown-item dropdownStyle"} 
                                        xstyle={{minWidth: 120}}  key={item.key} to={item.target}><Typography>{item.label.toUpperCase()}</Typography></Link>
                                    }

                                }

                                )
                            }
                        </ul>
                    </li>

                    {
                        // remaining page tabs
                        menuItems
                        .filter(item => item.inMenu && !item.inDropdown && item.target !== "/")
                        .map((item) => 
                            <Link 
                            onClick={() => dispatch(setActivePage(item.key))} 
                            className={activePage===item.key? activeTabStyle : inactiveTabStyle} 
                            xstyle={{minWidth: 120}} key={item.key} to={item.target}><Typography className={activePage===item.key? activeTextStyle : inactiveTextStyle}>{item.label.toUpperCase()}</Typography></Link>
                        )
                    }
                </div>
            }
        </>
    )

}
export default NavBar;