import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton, Tab, Tabs, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CoverImage from './CoverImage.js';
import './FeatureCard.css';
import {connect} from 'react-redux';

class FeatureCard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            active: props.isActive,
            activeTab: props.isActive ? 0 : -1,
            doneAnimating: props.isActive
        };
    }
    
    toggleCard = () => {

        this.setState({...this.state, active: !this.state.active});
        const self = this;
        const newState = !this.state.active;
        setTimeout(() => {
            self.setState({...self.state, doneAnimating: newState, activeTab: 0});
        }, 300)
        if(this.props.onFlip) this.props.onFlip(!this.state.active);
    };

    handleChange = (event, newValue) => {
        this.setState({...this.state, activeTab: newValue});
        console.log(newValue)
    }

    renderTabs() {
        if(!this.props.children) return this.props.children;
        return this.props.children
        .filter((child) => child)
        .map((child, index) => {
            return <Tab label={child.props.label} key={index}/>
        });
    }

    renderChildren() {
        if(!this.props.children) return this.props.children;
        return this.props.children
        .filter((child) => child)
        .map((child, index) => {
            if(child) return React.cloneElement(child, {index, value: this.state.activeTab, key: index});
            else return child;
        })
    }

    render() {
        const children = this.renderChildren();
        const tabs = this.renderTabs();
        const { header } = this.props;
        return(
        <Card className={"flip-card " + (this.state.active ? "active card" : "inactive card")}>
            <CardContent className="flip-card-inner">
                <div className="flip-card-front" onClick={this.toggleCard} >
                    {/* <Typography variant="h5" component="h2">{this.props.header}</Typography> */}
                    {/* 2022.03, LL, add cover images */}
                    <CoverImage img={this.props.coverImg} title={this.props.header}/>
                </div>
                <div className="flip-card-back">
                    <Toolbar>
                        <Typography className="toolbarHeader" style={{ fontWeight: 600 }}>{header}</Typography>
                        <Tabs
                            value={this.state.activeTab}
                            indicatorColor="primary"
                            onChange={this.handleChange}
                            className="featureTabs"
                            orientation={this.props.isXSmall? "vertical":"horizontal"}
                        >
                            { tabs }
                        </Tabs>
                        <Box className="toolbarButtons"><IconButton onClick={this.toggleCard}><CloseIcon></CloseIcon></IconButton></Box>
                    </Toolbar>
                    { children }
                </div>
            </CardContent>
        </Card>
        );
        }
}

// this is the way in class component to access the global state
function mapStateToProps(state) {
    const isXSmall = state.app.isXSmall;
    return {isXSmall};
}

export default connect(mapStateToProps)(FeatureCard);
