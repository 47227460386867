import { createSlice } from '@reduxjs/toolkit';


export const appSlice = createSlice({
    name: 'app',
    initialState: {
        activePage: 0,
        isMobile: false,
        isXSmall:false
    },
    reducers: {
        setActivePage: (state, action) => {
            state.activePage = action.payload;
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        setIsXSmall: (state, action) => {
            state.isXSmall = action.payload;
        }
    }
})

export const selectActivePage = state => state.app.activePage;
export const selectIsMobile = state => state.app.isMobile;
export const selectIsXSmall = state => state.app.isXSmall;
export const { setActivePage, setIsMobile, setIsXSmall } = appSlice.actions;
export default appSlice.reducer;