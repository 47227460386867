import './App.css'
import { Container, Divider, Drawer, Grid, IconButton, List, ListItem, responsiveFontSizes, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
import { Switch, Link, BrowserRouter as Router, Route } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';

import SiteNotice from './pages/SiteNotice';
import AboutUs from './components/AboutUs';
import {useSelector,useDispatch} from 'react-redux';
import menuItems from './menuItems.js';
import findOpenedMenuKey from './findOpenedMenuKey.js';
import NavBar from './components/NavBar';
import { setActivePage, setIsMobile, setIsXSmall, selectActivePage } from './features/appSlice.js';

const menuItemToRoute = (menuItem) => (<Route component={menuItem.component} path={menuItem.target} key={menuItem.target} />);

function App() {
    const activePage = useSelector(selectActivePage);
    const dispatch = useDispatch();

    const index = findOpenedMenuKey(menuItems, window.location.pathname.toLowerCase());
    dispatch(setActivePage(index));

    let [drawerIsOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
        setDrawerOpen(open);
    }
    
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));

    dispatch(setIsMobile(isMobile));
    dispatch(setIsXSmall(isXSmall));

    return (
        <ThemeProvider theme={theme}>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            className="App"
        >
            <Router>
                { isMobile && <Drawer
                    anchor="main_anchor"
                    open={drawerIsOpen}
                    onClose={toggleDrawer(false)}
                    >
                        <List>
                            {menuItems.filter(menuItem => menuItem.link === undefined).map((item, index) => <ListItem onClick={toggleDrawer(false)} button key={index} component={Link} to={item.target}>{item.label}</ListItem>)}
                        </List> 
                    </Drawer>
                }

                <NavBar/>

                {/* 2022.04, LL, add more padding when screen size is large. activePage this state controls padding and highlight. */}
                {(activePage !== 0) &&
                    <Container>
                        <Box sx={{paddingTop: isMobile? "2em" : "8em", paddingBottom: "2em"}} ><Divider/></Box>
                    </Container>
                }

                {(activePage === 0) &&
                    <Container>
                        <Box sx={{paddingTop: isMobile? "0em" : "6em", paddingBottom: "2em"}} ></Box>
                    </Container>
                }

                <Switch>
                    {menuItems.filter(menuItem => menuItem.link === undefined).filter(element => element.target !== "/").map(menuItemToRoute)}
                    {/* Route "/" needs to be last, otherwise would be matched for all routes */}
                    {menuItems.filter(menuItem => menuItem.link === undefined).filter(element => element.target === "/").map(menuItemToRoute)}
                    <Route component={SiteNotice} path="/site-notice" key="/site-notice"/>
                </Switch>
                <Box component={"footer"} style={{ backgroundColor: "#1B1C1D", minHeight: "15em", marginTop: "5em", width: "100%" }}>
                    <Container>
                        <Grid container sx={{ package: 2 }}>
                            <Grid item xs>
                                <Box sx={{ m: 2 }}>
                                    <AboutUs></AboutUs>
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" flexItem style={{ backgroundColor: "white" }} />
                            <Grid item xs>
                                <Box sx={{ m: 2 }} className="linkBox">
                                    <Typography><Link onClick={() => dispatch(setActivePage(-1))} to="/imprint">Imprint</Link></Typography>
                                    <Typography><Link onClick={() => dispatch(setActivePage(-1))} to="/data-protection-information">Data Protection Policy</Link></Typography>
                                    <Typography><Link onClick={() => dispatch(setActivePage(-1))} to="/license-manager">License Manager</Link></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                { isMobile && 
                    <IconButton style={{backgroundColor: "black",display: "block", position: "fixed", right: "2em", bottom: "3em"}} onClick={() => setDrawerOpen(!drawerIsOpen)}>
                        <MenuIcon color="secondary" />
                    </IconButton>
                }
            </Router>
        </Box>
        </ThemeProvider>
    )
}

export default App;
